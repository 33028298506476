div.pmi_web__Disclaimer {
  padding: var(--pmi-spacing-sm);
  border-radius: var(--pmi-border-radius-disclaimer);

  &[data-pmiweb-type='error'] {
    color: var(--pmi-color-error);
    background-color: var(--pmi-color-error-lighter);
  }
  &[data-pmiweb-type='warning'] {
    color: var(--pmi-color-warn);
    background-color: var(--pmi-color-warn-lighter);
  }
  &[data-pmiweb-type='info'] {
    color: var(--pmi-color-info);
    background-color: var(--pmi-color-info-lighter);
  }
  &[data-pmiweb-type='success'] {
    color: var(--pmi-color-success);
    background-color: var(--pmi-color-success-lighter);
  }
}
