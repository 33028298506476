@import 'tailwind';

h1 {
  font-family: var(--pmi-font-family-headings);
  font-weight: var(--pmi-font-weigth-headings);
  font-size: 24px;
  line-height: 36px;
}

h2 {
  font-family: var(--pmi-font-family-headings);
  font-weight: var(--pmi-font-weigth-headings);
  font-size: 16px;
  line-height: 24px;
}

h3 {
  font-family: var(--pmi-font-family-headings);
  font-weight: var(--pmi-font-weigth-headings);
  font-size: 14px;
  line-height: 20px;
}

h4 {
  font-family: var(--pmi-font-family-headings);
  font-weight: var(--pmi-font-weigth-headings);
  font-size: 12px;
  line-height: 18px;
}

p {
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
}

small {
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
}
