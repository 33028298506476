@keyframes pmi_web__spin {
  to {
    transform: rotate(1turn);
  }
}

div.pmi_web__LoadingSpinner {
  color: var(--pmi-color-primary);
  display: block;
  width: -moz-fit-content;
  width: fit-content;

  animation-name: pmi_web__spin;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
}
